import { locale } from 'moment'
import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '../components/404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/homeComponents/home.vue'),
    meta: { data: [{ title: '首页' }], requireAuth: true },
    children: [
      { // 首页信息
        path: '/home',
        name: 'homePage',
        component: () => import('../views/homeComponents/index.vue'),
        meta: { data: [{ title: '首页' }], requireAuth: true }
      },
      { // 产品
        path: '/product',
        name: 'product',
        component: () => import('../views/product/index.vue'),
        meta: { data: [{ title: '产品' }], requireAuth: true },
        children: [
          {
            path: '/teacher',
            name: 'teacher',
            component: () => import('../views/product/teacher.vue'),
            meta: ['产品']
          },
          {
            path: '/student',
            name: 'student',
            component: () => import('../views/product/student.vue'),
            meta: ['学生']
          },
          {
            path: '/school',
            name: 'school',
            component: () => import('../views/product/school.vue'),
            meta: ['学校']
          }]
      },
      { // 学堂
        path: '/studyRoom',
        name: 'studyRoom',
        component: () => import('../views/studyRoom/index.vue'),
        meta: { data: [{ title: '学堂' }], requireAuth: true }
      },
      { // 资讯
        path: '/consulting',
        name: 'consulting',
        component: () =>
          import('../views/consulting/index.vue'),
        meta: { data: [{ title: '资讯', url: '/consulting' }, { title: '新闻资讯', url: '/consulting' }], requireAuth: true }
      },
      { // 资讯
        path: '/consultingDetail',
        name: 'consultingDetail',
        component: () =>
          import('../views/consulting/consultingDetail.vue'),
        meta: { data: [{ title: '新闻资讯', url: '/consulting' }, { title: '新闻资讯详情', url: '/consultingDetail' }], requireAuth: true }
      },
      { // 书屋
        path: '/bookRoom',
        name: 'bookRoom',
        component: () => import('../views/bookRoom/index.vue'),
        meta: { data: [{ title: '书屋', url: '/bookRoom', requireAuth: true }, { title: '经典阅读', url: '/bookRoom' }], requireAuth: true }
      },
      { // 书屋 经典阅读
        path: '/bookDetail',
        name: 'bookDetail',
        component: () => import('../views/bookRoom/bookDetail.vue'),
        meta: { data: [{ title: '书屋', url: '/bookRoom', requireAuth: true }, { title: '经典阅读', url: '/bookDetail' }], requireAuth: true }
      }, //
      { // 书屋 节目推荐
        path: '/programRecommend',
        name: 'programRecommend',
        component: () => import('../views/bookRoom/programRecommend.vue'),
        meta: { data: [{ title: '书屋', url: '/bookRoom', requireAuth: true }, { title: '书目推荐', url: '/programRecommend' }], requireAuth: true }
      },
      { // 书屋 书籍推荐
        path: '/bookRecommend',
        name: 'bookRecommend',
        component: () => import('../views/bookRoom/bookRecommend.vue'),
        meta: { data: [{ title: '书屋', url: '/bookRoom', requireAuth: true }, { title: '书籍推荐', url: '/bookRoom' }], requireAuth: true }
      },
      { // 活动
        path: '/active',
        name: 'active',
        component: () => import('../views/active/index.vue'),
        meta: ['活动']
      },
      // allActive
      { // 全部活动
        path: '/allActive',
        name: 'allActive',
        component: () => import('../views/active/allActive.vue'),
        meta: { data: [{ title: '活动', url: '/active', requireAuth: true }, { title: '全部活动', url: '/allActive' }], requireAuth: true }
      },
      { // 全部活动
        path: '/activeVideo',
        name: 'activeVideo',
        component: () => import('../views/active/activeVideo.vue'),
        meta: { data: [{ title: '活动', url: '/active', requireAuth: true }, { title: '全部活动', url: '/allActive' }], requireAuth: true }
      },
      { // 考场
        path: '/examinationRoom',
        name: 'examinationRoom',
        component: () => import('../views/examinationRoom/index.vue'),
        meta: { data: [{ title: '考场', url: '/examinationRoom', requireAuth: true }, { title: '政策解读', url: '/examinationRoom' }], requireAuth: true }
      },
      { // 考场 政策解读
        path: '/policy',
        name: 'policy',
        component: () => import('../views/examinationRoom/policy.vue'),
        meta: { data: [{ title: '考场', url: '/examinationRoom', requireAuth: true }, { title: '政策解读', url: '/policy' }], requireAuth: true }
      },
      { // 考场 政策解读
        path: '/testPaper',
        name: 'testPaper',
        component: () => import('../views/examinationRoom/testPaper.vue'),
        meta: { data: [{ title: '考场', url: '/examinationRoom', requireAuth: true }, { title: '高考真题', url: '/testPaper' }], requireAuth: true }
      }
    ]
  },
  { // 登录
    path: '/login',
    name: 'login',
    component: () => import('../views/user/login.vue')
  },
  { // 注册
    path: '/register',
    name: 'register',
    component: () => import('../views/user/register.vue')
  },
  { // 忘记密码
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import('../views/user/forgetPassword.vue')
  },
  // { //榜单
  //   path: '/list',
  //   name: 'list',
  //   component: () => import('../views/list/index.vue'),
  //   meta: ['榜单'],
  // },

  // { //下载
  //   path: '/upload',
  //   name: 'upload',
  //   component: () => import('../views/upload/index.vue'),
  //   meta: ['下载']
  // },
  { // 路由重定向
    path: '/',
    redirect: '/home'
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
]

const router = new VueRouter({
  routes
})

// 添加这下面一段代码，就可以解决报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) {
//     if (localStorage.getItem('userInfo')) {
//       next()
//     } else {
//       next('/login')
//     }
//   } else {
//     next()
//   }
// })
