<template>
  <div>
    <div class="content">
        <img src="../assets/img/404.png"/>
        <p><span id='myTimer'>3</span>秒后<a href="https://www.e-snails.com">返回首页</a></p>
    </div>
  </div>
</template>

<script>
export default {
    name: "404",
    data(){
        return {
            timer: null
        }
    },
    mounted(){
        let t = 3
        this.timer = setInterval(()=>{
            t--
            document.getElementById('myTimer').innerText=t
            if(t<=0){
                t=1
                this.timer = null
                window.location="https://www.e-snails.com/"
            }
        },1000)
    }
}
</script>

<style lang="less" scoped>
    .content{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
</style>