// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import antd, { message, Spin } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import axios from 'axios'
import http from 'utils/http'
Vue.use(antd)
message.config({
  top: '100px',
  duration: 2,
  maxCount: 3
})
Spin.setDefaultIndicator({
  indicator: h => {
    return '<a-icon type = "loading" / >'
  }
})
Vue.config.productionTip = false
Vue.prototype.$fetch = (request, onError = () => {}, afterResponse) => {
  const userInfo = getUserInfo()
  // console.log('userInfo', userInfo)
  // request.headers = Object.assign({}, request.headers, {
  //   Authorization: userInfo && userInfo.token ? userInfo.token : null
  // })
  let classId = ''
  let bookId = request.body && request.body.bookId
  if (!bookId) {
    bookId = shareState.state.bookId || localStorage.getItem('syzg-bookId') || 1
  }
  if (
    userInfo &&
        userInfo.class &&
        userInfo.class.length &&
        userInfo.class[0].classId) classId = userInfo.class[0].classId
  request.body = Object.assign({}, request.body, {})

  return fetch(request, err => {
    if (err.response && err.response.status === 401) {
      router.currentRoute.name !== 'Login' && router.push({
        name: 'Login'
      })
      Modal.destroyAll()
    } else onError(err)
  }, afterResponse => {
    if (afterResponse.resultCode === 1200) {
      // message.info(afterResponse.resultMessage)
      router.push({
        name: 'MyBooks'
      })
    }
  })
}
Vue.prototype.$message = message
Vue.prototype.$axios = axios
Vue.prototype.$http = http

Vue.prototype.$clientId = 'hytcebook'
Vue.prototype.$clientSecret = 'hytcebook.7,f@e'
// 测试环境
// Vue.prototype.$teaUrl = 'http://localhost:8889/'
// Vue.prototype.$stuUrl = 'http://localhost:8889/'
// Vue.prototype.$resBasePath = 'http://192.168.6.111:8082/spath'
// Vue.prototype.$baseServer = 'http://192.168.6.111:6620/'
// Vue.prototype.$loginServer = 'http://192.168.6.111:6022/'
// Vue.prototype.$loginServer = 'http://192.168.6.111:6022/'
// Vue.prototype.$baseUrl = 'http://192.168.6.111:6092/'
// 生产环境
// Vue.prototype.$teaUrl = 'https://www.e-snails.com/'
// Vue.prototype.$stuUrl = 'https://www.e-snails.com/'
// Vue.prototype.$resBasePath = 'https://file.e-snails.com/spath'
// Vue.prototype.$loginServer = 'https://auth.greatwallchinese.com/'
// Vue.prototype.$baseServer = 'https://server.e-snails.com/' // 教师端
// Vue.prototype.$baseUrl = 'https://www.e-snails.com/'

Vue.prototype.$teaUrl = process.env.VUE_APP_TeaUrl
Vue.prototype.$stuUrl = process.env.VUE_APP_StuUrl
Vue.prototype.$resBasePath = process.env.VUE_APP_ResBaseUrl
Vue.prototype.$loginServer = process.env.VUE_APP_LoginServer
Vue.prototype.$baseServer = process.env.VUE_APP_BaseServer // 教师端
Vue.prototype.$baseUrl = process.env.VUE_APP_BaseUrl

/* eslint-disable no-new */
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
