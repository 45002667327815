<template>
  <div class="footerBox">
    <a-row class="wrap">
      <a-col :span="8" class="wrapLeft">
        <div class="bookTitle">素养植根融媒体教材</div>
        <a-row class="bookContent">
          <a-col :span="6" class="mc">
            <div>出&nbsp; 品 方</div>
            <div>学术支持</div>
            <div>技术支持</div>
          </a-col>
          <a-col :span="18" class="name">
            <div>山东人民出版社有限公司</div>
            <div>北京师范大学语文素养植根工程研发团队</div>
            <div>北京汉雅天诚教育科技有限公司</div>
          </a-col>
        </a-row>
        <div class="bei_an">鲁ICP备14038021号-3</div>
        <a-row class="bookContent">
          <a-col :span="6" class="mc">
            <div>地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址</div>
            <div>联系电话</div>
          </a-col>
          <a-col :span="18" class="name">
            <div>济南市市中区舜耕路517号</div>
            <div>0531-82098011</div>
          </a-col>
        </a-row>
        <div class="ban_quan">版权所有 未经授权不得复制</div>
      </a-col>
      <a-col :span="4" class="wrapCenter" v-show="false">
        <div class="bookTitle" v-show="false">导航</div>
        <ul class="dao_hang" v-show="false">
          <li @click="routerGo(item,index)" v-for="(item,index) in daohangList" :key="index">{{ item.name }}</li>
        </ul>
      </a-col>
      <a-col :span="16" class="wrapRight">
        <div class="upload">素养植根客户端下载</div>
        <a-row  class="content" type="flex" justify="center" align="top">
          <a-col :span="6" class="ewm">
            <img src="../assets/img/android_teacher.png" alt="">
          </a-col>
          <a-col :span="6" class="ewm">
            <img src="../assets/img/android_student.png" alt="">
          </a-col>
          <a-col :span="6" class="ewm">
            <img src="../assets/img/iOS_teacher.png" alt="">
          </a-col>
          <a-col :span="6" class="ewm">
            <img src="../assets/img/iOS_student.png" alt="">
          </a-col>
        </a-row>
      </a-col>
    </a-row>

  </div>

</template>

<script>
import logoImg from '@/assets/logo.png'

export default {
  name: 'Footer',
  data() {
    return {
      logoImg,
      daohangList: [
        {name: '素养植根', url: 'home'},
        {name: '产品', url: 'product'},
        {name: '考场', url: 'examinationRoom'},
        {name: '书屋', url: 'bookRoom'},
        {name: '资讯', url: 'consulting'},
        {name: '活动', url: 'allActive'}
        // { name: '大赛', url: 'competition' }
      ]
    }
  },
  methods: {
    routerGo(item, index) {
      sessionStorage.setItem('routeIndex', index)
      if (item.key == 'competition') {
        window.open(
          this.$stuUrl + 'page/qt/research/index.html', '_blank'
        )
      } else {
        this.$router.push(item.key)
      }
    }
  }
}
</script>

<style lang="less" scoped>
ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footerBox {
  height: 293px;
  background: #404040;

  .wrap {
    width: 1340px;
    padding: 30px 50px;
    margin: auto;

    .wrapLeft {
      .bookTitle {
        height: 28px;
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 28px;
        text-align: left;
      }

      .bei_an {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        margin-top: 55px;
        text-align: left;
      }

      .bookContent {
        margin-top: 10px;

        .mc {
          width: 48px;
          height: 25px;
          font-size: 12px;
          color: #f6f6f6;
          line-height: 25px;
          text-align: left;
          opacity: .6;
        }

        .name {
          margin-left: 10px;
          height: 25px;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 25px;
          text-align: left;
        }
      }

      .ban_quan {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        margin-top: 30px;
        text-align: left;
      }
    }

    .wrapCenter {
      .bookTitle {
        height: 28px;
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 28px;
        text-align: left;
      }

      .dao_hang {
        margin-top: 10px;
        text-align: left;
        height: 25px;
        line-height: 25px;
        color: #fff;
        font-size: 14px;
        cursor: pointer;

        li {
          &:hover {
            color: @primary-color;
          }
        }
      }
    }

    .wrapRight {



      .upload {
        height: 28px;
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 28px;
        text-align: left;
      }

      .content {
        margin-top: 15px;

        .ewm {
          text-align: left;


          .jiaoshi {
            color: #f6f6f6;
            font-size: 14px;
            margin-top: 3px;
            margin-bottom: 6px;
            opacity: .6;
          }

          .text {
            text-align: left;
            text-indent: 28px;
            height: 25px;
            line-height: 25px;
            color: #fff;
          }

          .textAnd {
            text-indent: 15px;
          }

          img {
            width: 180px;
            height: 180px;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

///deep/ .ant-col-12 {
//  display: block;
//  box-sizing: border-box;
//
//}

</style>
