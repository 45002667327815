import { JSEncrypt } from 'jsencrypt'

const CryptoJS = require('crypto-js')

class AuthUtils {
  constructor (encryptionKey) {
    this.encryptionKey = encryptionKey
  }

  generateRandomKey () {
    // 生成随机密钥
    const random = CryptoJS.lib.WordArray.random(24)
    return CryptoJS.enc.Base64.stringify(random)
  }

  encryptKey (keyBase64) {
    // 加密参数encryptionKey--使用RSA加密算法
    const encrypt = new JSEncrypt()
    encrypt.setPublicKey(this.encryptionKey)
    return encrypt.encrypt(keyBase64)
  }

  aesEncryptEBC (word, keySec) {
    // aes加密
    const aeskey = CryptoJS.enc.Utf8.parse(keySec)
    const srcs = CryptoJS.enc.Utf8.parse(word)
    const encrypted = CryptoJS.AES.encrypt(srcs, aeskey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    return encrypted.ciphertext.toString()
  }

  aesDecryptEBC (word, keySec) {
    // aes解密
    const aeskey = CryptoJS.enc.Utf8.parse(keySec)
    const encryptedHexStr = CryptoJS.enc.Hex.parse(word)
    const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
    const decrypt = CryptoJS.AES.decrypt(srcs, aeskey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
    return decryptedStr.toString()
  }
}

export default AuthUtils
