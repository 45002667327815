import axios from 'axios'
import AuthUtils from 'utils/AuthUtils'

const localhosts = process.env.VUE_APP_LoginServer
axios.interceptors.request.use(config => {
  // loading
  return config
}, error => {
  return Promise.reject(error)
})

axios.interceptors.response.use(response => {
  return response
}, error => {
  return Promise.resolve(error.response)
})

function checkStatus(response) {
  // loading
  // 如果http状态码正常，则直接返回数据
  if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
    return response
    // 如果不需要除了data之外的数据，可以直接 return response.data
  }
  // 异常状态下，把错误信息返回去
  return {
    status: 404,
    msg: '网络异常'
  }
}

function checkCode(res) {
  // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
  if (res.status === 404) {
    // alert(res.msg)
  }
  if (res.data && (!res.data.success)) {
    // alert(res.data.error_msg)
  }
  return res
}

export default {
  post(url, data) {
    // 加密
    const encryptionKey = window.localStorage.getItem('encryptionKey')
    const authUtils = new AuthUtils(encryptionKey)
    const keyBase64 = authUtils.generateRandomKey()
    const key = authUtils.encryptKey(keyBase64)
    const encryptionData = authUtils.aesEncryptEBC(JSON.stringify(data), keyBase64)

    const encrypParam = {}
    encrypParam.encryptionKey = key
    encrypParam.encryptionData = encryptionData

    return axios({
      method: 'post',
      baseURL: localhosts,
      url,
      data: encrypParam,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(
      (res) => {
        // console.log('接口参数', res)
        if (res && res.data && res.data.resultData && res.data.resultCode === '0') {
          if (res.data.resultData.encryptionData) {
            const jsonString = authUtils.aesDecryptEBC(res.data.resultData.encryptionData, keyBase64)
            const decryptedData = JSON.parse(jsonString)
            // console.log('解密参数：decryptedData', decryptedData)
            const response = res
            response.data.resultData = decryptedData
            return response
          } else {
            return res
          }
        } else {
          return res
        }
      }
    )
  },
  get(url, params) {
    return axios({
      method: 'get',
      baseURL: localhosts,
      url,
      params, // get 请求时带的参数
      timeout: 10000,
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }).then(
      (response) => {
        return checkStatus(response)
      }
    ).then(
      (res) => {
        return checkCode(res)
      }
    )
  }
}
